import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca token`}</strong>{` -- generate an OTT granting access to the CA`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca token <subject>
[--kid=<kid>] [--issuer=<name>]
[--cert-not-before=<time|duration>] [--cert-not-after=<time|duration>]
[--not-before=<time|duration>] [--not-after=<time|duration>]
[--password-file=<file>] [--provisioner-password-file=<file>]
[--output-file=<file>] [--kms=uri] [--key=<file>] [--san=<SAN>] [--offline]
[--revoke] [--x5c-cert=<file>] [--x5c-key=<file>] [--x5c-insecure]
[--sshpop-cert=<file>] [--sshpop-key=<file>]
[--ssh] [--host] [--principal=<name>] [--k8ssa-token-path=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca token`}</strong>{` command generates a one-time token granting access to the
certificates authority.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`subject`}</inlineCode>{`
The Common Name, DNS Name, or IP address that will be set by the certificate authority.
When there are no additional Subject Alternative Names configured (via the
--san flag), the subject will be added as the only element of the 'sans' claim
on the token.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`kid`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--san`}</strong>{`=`}<inlineCode parentName="p">{`dns|ip|email|uri`}</inlineCode>{`
Add `}<inlineCode parentName="p">{`dns|ip|email|uri`}</inlineCode>{` Subject Alternative Name(s) (SANs)
that should be authorized. A certificate signing request using this token must
match the complete set of SANs in the token 1:1. Use the '--san' flag multiple
times to configure multiple SANs.`}</p>
    <p><strong parentName="p">{`--principal`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`-n`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
Add the principals (user or host `}<inlineCode parentName="p">{`name`}</inlineCode>{`s) that the token is authorized to
request. The signing request using this token won't be able to add
extra names. Use the '--principal' flag multiple times to configure
multiple principals.`}</p>
    <p><strong parentName="p">{`--host`}</strong>{`
Create a host certificate instead of a user certificate.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period ends. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period starts. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--cert-not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period ends. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h". This flag is only supported on SSH certificates.`}</p>
    <p><strong parentName="p">{`--cert-not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period starts. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h". This flag is only supported on SSH certificates.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The `}<inlineCode parentName="p">{`uri`}</inlineCode>{` to configure a Cloud KMS or an HSM.`}</p>
    <p><strong parentName="p">{`--x5c-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--x5c-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--x5c-insecure`}</strong>{`
Use the JWT header 'x5cInsecure' instead of 'x5c'.`}</p>
    <p><strong parentName="p">{`--sshpop-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'sshpop' header of a JWT.`}</p>
    <p><strong parentName="p">{`--sshpop-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'sshpop' header.`}</p>
    <p><strong parentName="p">{`--nebula-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` in PEM format to store in the 'nebula' header of a JWT.`}</p>
    <p><strong parentName="p">{`--nebula-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'nebula' header.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The private key `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to sign the JWT. This is usually downloaded from
the certificate authority.`}</p>
    <p><strong parentName="p">{`--output-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The destination `}<inlineCode parentName="p">{`file`}</inlineCode>{` of the generated one-time token.`}</p>
    <p><strong parentName="p">{`--revoke`}</strong>{`
Create a token for authorizing 'Revoke' requests. The audience will
be invalid for any other API request.`}</p>
    <p><strong parentName="p">{`--renew`}</strong>{`
Create a token for authorizing 'renew' requests. The audience will
be invalid for any other API request.`}</p>
    <p><strong parentName="p">{`--rekey`}</strong>{`
Create a token for authorizing 'rekey' requests. The audience will
be invalid for any other API request.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Create a token for authorizing an SSH certificate signing request.`}</p>
    <p><strong parentName="p">{`--k8ssa-token-path`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Configure the `}<inlineCode parentName="p">{`file`}</inlineCode>{` from which to read the kubernetes service account token.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{` Most of the following examples assumes that `}<strong parentName="p">{`--ca-url`}</strong>{` and `}<strong parentName="p">{`--root`}</strong>{` are
set using environment variables or the default configuration file in
`}<inlineCode parentName="p">{`$STEPPATH/config/defaults.json`}</inlineCode>{`.`}</p>
    <p>{`Get a new token for a DNS. Because there are no Subject Alternative Names
configured (via the '--san' flag), the 'sans' claim of the token will have a
default value of `}{`['internal.example.com']`}{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token internal.example.com
`}</code></pre>
    <p>{`Get a new token for a 'Revoke' request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --revoke 146103349666685108195655980390445292315
`}</code></pre>
    <p>{`Get a new token for an IP address. Because there are no Subject Alternative Names
configured (via the '--san' flag), the 'sans' claim of the token will have a
default value of `}{`['192.168.10.10']`}{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token 192.168.10.10
`}</code></pre>
    <p>{`Get a new token with custom Subject Alternative Names. The value of the 'sans'
claim of the token will be `}{`['1.1.1.1', 'hello.example.com']`}{` - 'foobar' will not
be in the 'sans' claim unless explicitly configured via the '--san' flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token foobar --san 1.1.1.1 --san hello.example.com
`}</code></pre>
    <p>{`Get a new token that expires in 30 minutes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --not-after 30m internal.example.com
`}</code></pre>
    <p>{`Get a new token that becomes valid in 30 minutes and expires 5 minutes after that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --not-before 30m --not-after 35m internal.example.com
`}</code></pre>
    <p>{`Get a new token signed with the given private key, the public key must be
configured in the certificate authority:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token internal.smallstep.com --key token.key
`}</code></pre>
    <p>{`Get a new token for a specific provisioner kid, ca-url and root:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token internal.example.com \\
    --kid 4vn46fbZT68Uxfs9LBwHkTvrjEvxQqx-W8nnE-qDjts \\
    --ca-url https://ca.example.com \\
    --root /path/to/root_ca.crt
`}</code></pre>
    <p>{`Get a new token using the simple offline mode, requires the configuration
files, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token internal.example.com --offline
`}</code></pre>
    <p>{`Get a new token using the offline mode with all the parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token internal.example.com \\
    --offline \\
    --kid 4vn46fbZT68Uxfs9LBwHkTvrjEvxQqx-W8nnE-qDjts \\
    --issuer you@example.com \\
    --key provisioner.key \\
    --ca-url https://ca.example.com \\
    --root /path/to/root_ca.crt
`}</code></pre>
    <p>{`Get a new token for a 'Revoke' request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --revoke 146103349666685108195655980390445292315
`}</code></pre>
    <p>{`Get a new token in offline mode for a 'Revoke' request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --offline --revoke 146103349666685108195655980390445292315
`}</code></pre>
    <p>{`Get a new token for an SSH user certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token max@smallstep.com --ssh
`}</code></pre>
    <p>{`Get a new token for an SSH host certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token my-remote.hostname --ssh --host
`}</code></pre>
    <p>{`Generate a renew token and use it in a renew after expiry request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token --x5c-cert internal.crt --x5c-key internal.key --renew internal.example.com)
$ curl -X POST -H "Authorization: Bearer $TOKEN" https://ca.example.com/1.0/renew
`}</code></pre>
    <p>{`Generate a JWK provisioner token using a key in a YubiKey:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --kms yubikey:pin-value=123456 --key yubikey:slot-id=82 internal.example.com
`}</code></pre>
    <p>{`Generate an X5C provisioner token using a certificate in a YubiKey. Note that a
YubiKey does not support storing a certificate bundle. To make it work, you must
add the intermediate and the root in the provisioner configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca token --kms yubikey:pin-value=123456 \\
  --x5c-cert yubikey:slot-id=82 --x5c-key yubikey:slot-id=82 \\
  internal.example.com
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      